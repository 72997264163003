import React, { useEffect, useState } from 'react'
import { FaRegClock } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import {homedata} from '../Data/homedata';
import { useNavigate } from 'react-router-dom';
import HomeNav from '../Headerfooter/HomeNav';
import '../styles/Home.css';

const Home = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(600)
    useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          return 300; 
        } else {
          return prevTime - 1; 
        }
      });
    }, 1000); 
    return () => clearInterval(timer); 
});

const handleButtonClick1 = (id) => {
  navigate(`/detail/${id}`);
};

  return (<>  
     {/* <MobileHeader/>  */}
     <div className='mobile-tablet-only'>
     <HomeNav/>   
    <div className='container-fluid m-0 p-0 '> 
      {/* Home Banner   */}
      <div className='container-fluid m-0 p-0'>
      <img src="/assets/HomeImages/bannerhome.jpeg" width="100%" height="auto" alt='Banner Image'></img>
        <img src="/assets/HomeImages/bannerhome1.jpeg" width="100%" height="auto" alt='Banner Image'></img>
     </div>

      {/* Timer for Below  Banner #232f3e */}
      <div className='timer-background'>
        <div className='pb-2 row pt-3 m-0'   >
          <div className='col-7'>
          <h6 className='text-light text-end'> Today's Deals </h6>
          <h4 className='text-light text-end ' >
          <AiFillClockCircle className='clock-timer' style={{color:"#EFD0CA"}}/> 
           <span className='clock'  style={{color:"#EFD0CA"}} > {`${Math.floor(time / 60)}`.padStart(2, 0)}:
        {`${time % 60}`.padStart(2, 0)}</span></h4></div>
          <div className='col-5 mt-3 '>
            <div className='d-flex justify-content-end ' >
              <button className='buttoncommon text-center'> View All</button>
              </div>         
          </div>

      {/* All Products */}
      {/* Cards using Map method */}
        <div className="container m-0 p-0 " >
      <div className="row m-2  p-0 bg-light">
        {homedata.map((card ,id) => (
          <div key={card.id} className="col-6 col-md-4 col-lg-3 m-0 p-3  card-layout">
            <div className=" m-0 p-0"   onClick={() => handleButtonClick1(card.id)}>
              <img src={card?.badge} className=' badge1'/>
              <img src={card.imgSrc} className="d-block mx-auto"
               alt="Product Image"  width="125px" />
              <div className="card-body m-0 p-0">
                <h5 className="title text-center ">{card.title}</h5>
                <p className='title' style={{color:"#555555", fontSize:"12px",}}>{card.sold}</p>
                <h4 className="text-center homediscount">
                  <span className="over-through text-decoration-line-through">₹{card.originalPrice}</span>
                  <span className="redcolor "> {card.discount} OFF</span>
                </h4>
                <div className='text-center mt-3'>
                <img src="/assets/HomeImages/prime.png" height="25px" alt='prime'/>
                </div>
                <h3 className="text-center redcolorprice">Price : {card.price}</h3>
              </div>
              <div className='d-flex justify-content-center' style={{marginTop:"25px"}}>
              <button className='buttoncommon text-center' 
              onClick={() => handleButtonClick1(card.id)}> Buy Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
    <div className=' row bg-light pt-4 pb-4' >
      <div className='col-10'>
      <label className='ms-5' style={{fontWeight:"bold"}}>Terms & Conditions</label>

      </div>
      <div className='col-2'>
      <label style={{fontWeight:"bold", fontSize:"22px"}}>+</label>

      </div>
    {/* <FadeLoader /> */}
    </div>
      </div>
    </div>
    </div>
    <div className='desktop-only'>
      <div>  
        <div className='d-flex jusfity-content-center flex-column'>
        <img src='assets/HomeImages/error.jpeg' width="50%"  className='m-auto'/>
        <h4 className='text-center'>Uh Oh! Page not found!</h4>
        </div>
       
    </div>
    </div>
    </>
  )
}

export default Home;