import React from 'react';
import '../styles/Detail.css';

const card = () => {
  return (
    <>
    <div className='container product-extra'>
        <ul className='mainListBrands mb-0'>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (6).png"  width="50px" height="50px"/>
                    <span className='feature-title'>7 days replacement</span>
                </div>
            </li>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (1).png" width="50px" height="50px"/>
                    <span className='feature-title'> Free Delivery </span>
                </div>
            </li>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (5).png"  width="50px" height="50px"/>
                    <span className='feature-title'> 1 Year Warranty </span>
                </div>
            </li>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (3).png" width="50px" height="50px"/>
                    <span className='feature-title'> Pay on Delivery </span>
                </div>
            </li>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (4).png" width="50px" height="50px"/>
                    <span className='feature-title'>  Top Brand </span>
                </div>
            </li>
            <li>
                <div className='listBrandNew'>
                    <img src="/assets/HomeImages/i1 (2).png" width="50px" height="50px"/>
                    <span className='feature-title'>  Amazon Delivered </span>
                </div>
            </li>

        </ul>
    </div>

   
    </>
  )
}

export default card