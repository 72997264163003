import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaShoppingCart } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";

const Nav3 = () => {
    const navigate =useNavigate();

    const handlesubmit=()=>{
        navigate("/");
    }
  return (
    <nav className='p-0 pt-3 pb-3' style={{background:"#232f3e"}}>
    {/* <div className="container d-flex justify-content-start align-items-center">
      <button className="navbar-brand" onClick={handlesubmit}
       style={{ textAlign: "center" , border:"none",background:"transparent" }}>
        <img src="/assets/productsimage/logo.png" alt="Amazon" width="100" />
      </button>
    </div> */}
<div className='container-fluid'>
    <div className='row'>
    <div className='col-1'></div>
    <div className='col-4' >


    <button className="navbar-brand mx-auto" onClick={handlesubmit}
       style={{ textAlign: "center" , border:"none",background:"transparent"}}>
         <img src="/assets/productsimage/logo.png" alt="Amazon" width="100" />
         </button>


   
    </div>
    <div className='col-4'  ></div>
    <div className='col-1' >
    <IoSearchSharp style={{color:"white" ,fontSize:"20px"}}/>
    </div>
    <div className='col-1'>
    <FaShoppingCart style={{color:"white", fontSize:"20px"}}/>
    </div>

    </div>
    </div>
  </nav>
  )
}

export default Nav3
