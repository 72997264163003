import React, { useState } from 'react'
import '../styles/Global.css'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from '../Headerfooter/Footer';
import Nav2 from '../Headerfooter/nav2';

const PayType = () => {
  const navigate = useNavigate();
  const [paymentType,setPaymentType]=useState();
  const location = useLocation();
  const { ogprice } = location.state || {};
  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType) {
    navigate('/varify',{ state: { ogprice } });
    }
};
const handlePaymentTypeChange = (event) => {
  setPaymentType(event.target.value);
};
  return (
    <>
    <Nav2/>
    <div className='container m-0 p-0 pt-2 mt-2'>
     <div className='section-title  m-0 p-0 ps-3'>
        <h3 className='sub-title' style={{fontWeight:"650" , color:"#0F1111"}}>select a payment method</h3>
     </div>
     <form onSubmit={handleSubmit}>
    <div className='container-fluid'>
     <div className='row checkbox-box d-flex align-items-center  m-0 p-0  mt-4 pt-2 '>
        <div className='col-2 ps-4 pb-4'>   {/* d-flex justify-content-center */}
        <input  type="radio" name="paymentType" value="paymentType" className='small-checkbox'
           onChange={handlePaymentTypeChange} /></div>
        <div className='col-9 pt-2 pb-2'>
        <img src='assets/HomeImages/bank1.png' width="100%"/>
        <h6 style={{fontWeight:"bold",fontSize:"15px"}}>Add Debit/Credit/ATM  card </h6>
        <p style={{color:"#565959",fontSize:"14px"}}>you can save your cards as per new RBI Guidline.<a href='' style={{textDecoration:"none"}}>Learn More..</a> </p>
        </div>
      </div>

      <div className='row checkbox-box d-flex align-items-center  m-0 p-2 pt-3 '>
        <div className='col-2 ps-4 '>   {/* d-flex justify-content-center */}
        <input disabled type="radio" name="paymentType" value="paymentType" className='small-checkbox'
           onChange={handlePaymentTypeChange} /></div>
        <div className='col-9 pt-2 pb-2'>
        <h6 style={{color:"#9c9d9d",fontSize:"15px" ,fontWeight:"400"}}>EMI unavailable
           <span style={{color:"#007185",fontWeight:"400"}}>why?</span> </h6>
        </div>
      </div>
      </div>

     <div className='col-12 m-0 p-0 mt-4'>
       <button className="buttoncommondetail text-center w-100 d-block "
        style={{background:"#ffd814" , borderRadius:"8px",color:"black"}}
         type="submit" value="Submit">Continue</button>
       </div>
      </form>
    </div>
    <div  className=' m-0 p-0 '>
    <img src="assets/HomeImages/bottom.png" width="100%" className='m-0 p-0 mt-5 mb-4' height="auto"/>
    </div>
    <div className='m-0 p-0 container-fluid'>
    <Footer/>
    </div>
    </>
  )
}

export default PayType



 {/* <div className='row checkbox-box d-flex align-items-center mt-3 mb-1'>
        <div className='col-3 d-flex justify-content-center'>
        <input  type="radio" name="paymentType" className='small-checkbox' /></div>
        <div className='col-9'>
        <img src='assets/HomeImages/bank.jpeg' width="80%" className='mb-2'/>
        <img src='assets/HomeImages/bank1.png' width="100%"/>
        <h5>Add Debit / Credit  card </h5>
        <p>you can save your cards as per new RBI Guidline.<a href='' style={{textDecoration:"none"}}>Learn More..</a> </p>
        </div>
      </div>
      <div className='row checkbox-box d-flex align-items-center mb-1'>
        <div className='col-3 d-flex justify-content-center  '>
        <input disabled type="radio" name="paymentType" className='small-checkbox'/></div>
        <div className='col-9'>
        <h5>EMI unavailable why? </h5>
        </div>
      </div>
      <div className='row checkbox-box d-flex align-items-center'>
        <div className='col-3 d-flex justify-content-center'>
        <input disabled type="radio" name="paymentType" className='small-checkbox'/></div>
        <div className='col-9'>
        <h5>Pay on  Delivery</h5>
        </div>
      </div> */}
      {/* <div className='col-12  m-0 p-0  pt-5'>
      <button className='btn btn-warning d-block w-100 pt-2 pb-2'
       type="submit" value="send">Submit</button>
      </div> */}
     