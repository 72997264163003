import React from 'react'
import { useNavigate } from 'react-router-dom'
const Nav1 = () => {
    const navigate =useNavigate();

    const handlesubmit=()=>{
        navigate("/");
    }
  return (
    <nav className="navbar m-0 p-0 pt-1 pb-1" style={{background:"#f3f3f3" ,
    boxShadow: "rgba(50, 50, 93, 0.07) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.07) 0px 18px 36px -18px inset"}}>
    <div className="container d-flex justify-content-between align-items-center">
      {/* <!-- Logo in the Center --> */}
      <button className="navbar-brand mx-auto" onClick={handlesubmit}
       style={{ textAlign: "center" , border:"none",background:"transparent"}}>
        <img src="/assets/HomeImages/amazonl.png" alt="Amazon" width="100"/>
      </button>
    </div>
  </nav>
  )
}

export default Nav1
