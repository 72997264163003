import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaShoppingCart } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
import '../styles/Global.css'
import { BsFilePlusFill } from "react-icons/bs";

const HomeNav = () => {
    const navigate =useNavigate();

    const handlesubmit=()=>{
        navigate("/");
    }
  return (
    <nav className='p-0 pt-3 pb-3 bg-black'>
<div className='container-fluid'>
    <div className='row'>
    <div className='col-1'>
    <IoMenu style={{color:"white", fontSize:"20px"}}/>
    </div>
    <div className='col-4' >
    <img src="/assets/productsimage/logo.png" alt="Amazon" width="100" />
    </div>
    <div className='col-4'  ></div>
    <div className='col-1' >
    <BsFilePlusFill style={{color:"white" ,fontSize:"20px" ,fontWeight:"bold"}}/>
    </div>
    <div className='col-1'>
    <FaShoppingCart style={{color:"white", fontSize:"20px", marginLeft:"17px"}}/>
    <span className='msg'>1</span>
    </div>
    </div>

<div className='bg-black'>

<div className='mt-2  bg-danger custom-input' style={{ position: 'relative' }}>
<IoSearchSharp style={{
      position: 'absolute',
      left: '10px',  // Adjust this value as needed
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#878787',  // Icon color
      fontSize:"20px"
    }} />
        <input type='text' id="custom-input" style={{borderRadius:"none"}}/>
    </div>

</div>
   
    </div>
  </nav>
  )
}

export default HomeNav
