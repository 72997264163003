import React from 'react'

const Gallery = ({gallery}) => {
  return (
   <>
   <div style={{borderBottom:"3px solid #eee" }} className='py-3'>
    <div className='container'>
      <h5 style={{fontSize:"20px" ,color:"#212529"}}>Product Image Gallery</h5>
              {
                gallery.map((img,ind)=>{
                  return(<div className='mb-3' id={ind}>
                  <img src={img} width="100%"/>
                </div>);
                })
              }
    </div>
</div>

   </>
  )
}

export default Gallery