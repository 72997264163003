import React from 'react'
import { CiSearch } from "react-icons/ci";
import { FaCartShopping } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const Header = () => {
const navigate=useNavigate();
  // Optional: Handle navbar collapse on toggle
  const menuActive = () => {
    const navbar = document.getElementById("navbarSupportedContent");
    navbar.classList.toggle("show");
  }
  const goBack = () => {
    navigate(-1); // Navigate to the previous page in the history stack
  };
  const home=()=>{
    navigate("/")
  }

  return (
    <>
  <nav className="navbar">
  <div className="container d-flex justify-content-between align-items-center">
    {/* <!-- Back Arrow on the Left --> */}
    <button className="navbar-back-arrow" onClick={goBack} style={{ position: "absolute", 
      left: "15px", border:"none", background:"transparent"}}>
      <img src="/assets/HomeImages/arr.png" alt="Back" width="30" />
    </button>
    
    {/* <!-- Logo in the Center --> */}
    <button className="navbar-brand mx-auto" onClick={home} style={{ textAlign: "center"
      , border:"none", background:"transparent"
     }}>
      <img src="/assets/HomeImages/amazon.png" alt="Amazon" width="100" />
    </button>
  </div>
</nav>
    </>
  )
}

export default Header;
